import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import { PropsWithChildren } from 'react'
import circleIconStyles from './FontAwesomeIcon.module.scss'

export type FontAwesomeIconLoadProps = { 
  className?:string
  iconCode: IconDefinition
}

export const FontAwesomeIconLoad: React.FC<PropsWithChildren<FontAwesomeIconLoadProps>> = ({
  className,
  iconCode,
}) => (
  <>
    <FontAwesomeIcon
      className={classnames(className, circleIconStyles.icon)}
      icon={iconCode}
    />
  </>
)
