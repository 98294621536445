/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-case-declarations */
import dynamic from 'next/dynamic'
import React from 'react'
import { FAQPage, WithContext } from 'schema-dts'
import { ISbComponentType, ISbRichtext } from 'storyblok-js-client'
import { ColumnProps } from '../../components/Column'
import Download, { DownloadItem } from '../../components/Downloads'
import { FadeInWhenVisible } from '../../components/FadeInWhenVisible'
import { FlexibleContent } from '../../components/FlexibleContent'
import { Grid } from '../../components/Grid'
import { IImpactsData } from '../../components/Impact/variables'
import { ItemType } from '../../components/LogoList/variables'
import { GalleryItem, GalleryProps } from '../../components/SectionGallery'
import galleryClasses from '../../components/SectionGallery/gallery.module.scss'
import { StructuredData } from '../../components/StructuredData'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import { isEmpty } from '../../utils/isEmpty'
import { DownloadItemType } from '../DownloadList'
import logoGridStyles from '../LogoGrid/logoGrid.module.scss'
import { QuoteVariantSliceProps } from '../QuoteVariant'
import { RelatedContentSlideType } from '../RelatedContent'
import { ContentType as DetailVerticalTabsType } from '../ServiceDetails/DetailVerticalTab'
import { PureSliceComponent } from '../SliceComponent'
import rowClasses from './row.module.scss'
import { ComponentKeys } from './slices'
import { AccordionPanelProps } from './slices/Accordion'
import { SbBlokData } from '@storyblok/react'

export type FlexibleContentRowProps = SbBlokData & {
  // Common Variables
  content: SbBlokData & ColumnProps
  _uid: string
  title: string
  subtitle?: string
  isDarkMode: boolean
  dark_mode: boolean
  // Spacer
  hr?: boolean
  size?: 'large' | 'medium' | 'small'
  hidden_mobile?: boolean
  // Columns
  extra_gap: boolean
  extra_gap_select: '' | 'extra' | 'extraExtra'
  align_vertical: boolean
  wider_column: '' | 'left' | 'right'
  row_width: 'standard' | 'constrained' | 'wide' | 'blog'
  reverse_row: boolean
  one_col_width: '' | '40' | '50' | '60' | '70' | '80'
  // Accordions
  showStructuredData: boolean
  panels: AccordionPanelProps[]
  constrained: boolean
  alignment: '' | 'centre' | 'left' | 'right'
  // Downloads
  downloads: DownloadItemType[]
  // Gallery
  Dark: GalleryProps
  items: GalleryItem[]
  // LogoGrid
  logos: ItemType[]
  light_bg: boolean
  // Two Col CTA
  right_column_content: string
  url: {
    cached_url: string
    fieldtype: string
    id: string
    linktype: string
    url: string
  }
  icon?: string
  icon_colour?: string
  button_text?: string
  rightActionText?: string
  posts: RelatedContentSlideType[]
  introContent: ISbRichtext
  // Detail Tabs
  tabs: DetailVerticalTabsType[]
  // Table
  table_content: {
    thead:{ value: string }[]
    tbody:{component: string; body: {value: string}[]}[]
  }
  withHeader: boolean
  // Impacts
  data: IImpactsData[]
  // Quote
  className: string
  quote_description: string
  author: string
  position: string
  //  Get in touch form
  re_direct_to: {
    cached_url: string
  }
}

type FlexibleContentRowContextType = {
  i: number
  row: ISbComponentType<ComponentKeys> & Record<string, unknown>
}

export const FlexibleContentRowContext = React.createContext<FlexibleContentRowContextType | null>(null)

const HeaderTwo: React.FC = (props) => (
  <Grid container>
    <h2>
      {props.children}
    </h2>
  </Grid>
)

const AccordionSlice = dynamic(() => import('./slices/Accordion'))
const Columns = dynamic(() => import('./slices/Columns'))
const Spacer = dynamic(() => import('./slices/Spacer'))
const DetailVerticalTab = dynamic(() => import('../ServiceDetails/DetailVerticalTab'))
const DesktopGallery = dynamic(() => import('../../components/SectionGallery/DesktopGallery'))
const RelatedContent = dynamic(() => import('../RelatedContent'))
const LogoGrid = dynamic(() => import('../LogoGrid'))
const Table = dynamic(() => import('../Table'))
const Introduction = dynamic(() => import('../Introduction'))
const Impact = dynamic(() => import('../../components/Impact'))

// @ts-ignore
const ResponsiveGallery = dynamic(() => import('../../components/SectionGallery/ResponsiveGallery').then((mod) => mod.ResponsiveGallery))
// @ts-ignore
const TwoColumnCallAction = dynamic(() => import('../../components/TwoColumnCTA').then((mod) => mod.TwoColumnCallAction))
// @ts-ignore
const QuoteVariantSlice: React.ComponentType<QuoteVariantSliceProps> = dynamic(() => import('../QuoteVariant').then((mod) => mod.QuoteVariantSlice))
// @ts-ignore
const AtomixFullForm = dynamic(() => import('../../components/Forms/AtomixFullForm').then((mod) => mod.AtomixFullForm))

export const FlexibleContentRow: PureSliceComponent<FlexibleContentRowProps, { i: number; customClass?: string }> = ({ slice, customClass }) => {
  const {
    // Common Variables
    _uid,
    component,
    isDarkMode,
    title,
    subtitle,
    // Spacer
    size,
    hr,
    hidden_mobile,
    // Columns
    content,
    extra_gap_select,
    align_vertical,
    wider_column,
    row_width,
    reverse_row,
    one_col_width,
    // Accordions
    panels,
    alignment,
    constrained,
    showStructuredData,
    // Downloads
    downloads,
    Dark,
    // Gallery
    items,
    dark_mode,
    // Two col CTA
    right_column_content,
    button_text,
    url,
    icon,
    icon_colour,
    // Related content
    posts,
    // Introduction
    introContent,
    // Tabs
    tabs,
    // Table
    table_content,
    withHeader,
    // Impacts
    data,
    // LogoGrid
    logos,
    light_bg,
    // Quote
    className,
    quote_description,
    author,
    position,
  } = slice
  const breakpointMaxLg = useMediaQuery('(max-width: 1023px)')


  switch (component) {
  case 'accordion':
    let structuredData: WithContext<FAQPage> | undefined

    if (showStructuredData && panels) {
      structuredData = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: panels.filter(({ structuredTitle, structuredContent }: any) => {
          return (!isEmpty(structuredTitle) && !isEmpty(structuredContent))
        }).map(({ structuredTitle, structuredContent }: any, i: number) => {
          return {
            '@type': 'Question',
            name: structuredTitle,
            acceptedAnswer: {
              '@type': 'Answer',
              text: structuredContent,
            },
          }
        }),
      }
    }

    return (
      <FlexibleContent row customClass={customClass}>
        {structuredData && <StructuredData schema={structuredData} />}
        <AccordionSlice slice={{ _uid: _uid, component: component, panels: panels, isDarkMode: isDarkMode, alignment: breakpointMaxLg ? '' : alignment, constrained: !breakpointMaxLg && constrained }} />
      </FlexibleContent>
    )
  case 'columns':
    return (
      <FlexibleContent row customClass={customClass}>
        {/** @ts-ignore */}
        <Columns slice={{ one_col_width: one_col_width, reverse_row: reverse_row, wider_column: wider_column, component: component, _uid: _uid, content: content, extra_gap_select: extra_gap_select, align_vertical: align_vertical, row_width: row_width }} />
      </FlexibleContent>
    )
  case 'detail_vertical_tabs':
    return tabs && tabs.length > 0 ? (
      <FlexibleContent row customClass={customClass}>
        <DetailVerticalTab slice={{ title: title, tabs: tabs }} />
      </FlexibleContent>
    ) : null
  case 'fcp_downloads':
    return downloads && downloads?.length > 0 ? (
      <FlexibleContent row customClass={customClass}>
        <Download constrained={!breakpointMaxLg && constrained}>
          {downloads.map((item: any, idx: number) => (
            <DownloadItem key={idx} title={item.title} type={item.file_type} size={Number.parseInt(item.file_size)} downloadUrl={item?.file?.filename} />
          ))}
        </Download>
      </FlexibleContent>
    ) : null
  case 'fcp_gallery':
    return items && items.length > 0 ? (
      <>
        {!breakpointMaxLg ? (
          <FlexibleContent row customClass={customClass}>
            <div className={Dark ? galleryClasses['container__dark'] : galleryClasses.container}>
              <div className={galleryClasses.titleContainer}>
                {title && (
                  <FadeInWhenVisible animation={'easySlidingDownFadeOut'}>
                    <HeaderTwo>{title}</HeaderTwo>
                  </FadeInWhenVisible>
                )}
              </div>
              <DesktopGallery description={subtitle} dark items={items}/>
            </div>
          </FlexibleContent>
        ) : (
          <div className={Dark ? galleryClasses['container__dark'] : galleryClasses.container}>
            <div className={galleryClasses.titleContainer}>
              <FlexibleContent row customClass={customClass}>
                {title && (
                  <FadeInWhenVisible animation={'easySlidingDownFadeOut'}>
                    <HeaderTwo>{title}</HeaderTwo>
                  </FadeInWhenVisible>
                )}
              </FlexibleContent>
            </div>
            <div className={galleryClasses.responsiveContainer}>
              <ResponsiveGallery items={items}/>
            </div>
          </div>
        )}
      </>
    ) : null
  case 'fcp_related_content':
    return posts && posts?.length > 0 ? (
      <FlexibleContent row customClass={customClass}>
        <RelatedContent slice={{ posts: posts }} />
      </FlexibleContent>
    ) : null
  case 'fcp_two_col_cta':
    return title && right_column_content ? (
      <FlexibleContent row customClass={customClass}>
        <div className={dark_mode ? (rowClasses.dark_container) : (rowClasses.container)}>
          <TwoColumnCallAction icon={icon} icon_colour={icon_colour} leftColumnBody={title} rightColumnBody={right_column_content} rightActionText={button_text} rightActionHref={url.cached_url} isDarkMode={dark_mode}  />
        </div>
      </FlexibleContent>
    ) : null
  case 'introduction':
    return title ? (
      <FlexibleContent row customClass={customClass}>
        <Introduction slice={{ title: title, introContent: introContent }} />
      </FlexibleContent>
    ) : null
  case 'logo_grid':
    return logos && logos?.length > 0 && logoGridStyles.masonryGalleryGrid ? (
      <LogoGrid slice={{ logos: logos, title: title, light_bg: light_bg }} />
    ) : null
  case 'project_details_quote':
    return component === 'project_details_quote' && quote_description ? (
      <FlexibleContent row customClass={customClass}>
        <QuoteVariantSlice slice={{ className: className, author: author, isDarkMode: isDarkMode, position: position, quote_description: quote_description }} />
      </FlexibleContent>
    ) : null
  case 'project_impact':
    return data ? (
      <FlexibleContent row customClass={customClass}>
        <Impact title={title} data={data} />
      </FlexibleContent>
    ) : null
  case 'spacer':
    return (
      <FlexibleContent row customClass={customClass}>
        <Spacer slice={{ hr: hr, size: size, _uid: _uid, component: component, hidden_mobile: hidden_mobile }} />
      </FlexibleContent>
    )
  case 'table':
    return table_content && (
      <FlexibleContent row customClass={customClass}>
        <Table slice={{ constrained: !breakpointMaxLg && constrained, table_content: table_content, isDarkMode: isDarkMode, withHeader: withHeader }} />
      </FlexibleContent>
    )
  case 'get_in_touch_form':
    return (
      <FlexibleContent row customClass={customClass}>
        {/** @ts-ignore */}
        <AtomixFullForm slice={slice} />
      </FlexibleContent>
    )
  default:
    return null
  }
}
