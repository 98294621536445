import { SbBlokData } from '@storyblok/react'
import React from 'react'
import { AlternateBackground } from '../../components/AlternateBackground'
import { FlexibleContent } from '../../components/FlexibleContent'
import { PureSliceComponent, withSlice } from '../SliceComponent'
import { FlexibleContentRow, FlexibleContentRowProps } from './flexibleContentRow'

export type AltBgProps = {
  alt_bg_curve: boolean
  alt_bg_image: {
    filename: string
  }
  alt_bg_variant: 'light' | 'dark' | 'image'
}

export type FlexibleContentProps = SbBlokData & AltBgProps & {
  fcp_component: FlexibleContentRowProps[]
}

type FlexibleContentContextType = FlexibleContentProps

export const FlexibleContentContext = React.createContext<FlexibleContentContextType | null>(null)

export const FlexibleContentSlice: PureSliceComponent<FlexibleContentProps> = ({ slice }) => {
  const {
    fcp_component,
    alt_bg_variant,
    alt_bg_image,
    alt_bg_curve,
  } = slice

  return (
    <FlexibleContent>
      <AlternateBackground variant={alt_bg_variant} imageSrc={alt_bg_image?.filename} curveOutline={alt_bg_curve}>
        <FlexibleContentContext.Provider value={slice}>
          {fcp_component && fcp_component.length > 0  &&fcp_component.map((row, i) => <FlexibleContentRow slice={row} i={i} key={row._uid} />)}
        </FlexibleContentContext.Provider>
      </AlternateBackground>
    </FlexibleContent>
  )
}

export default withSlice(FlexibleContentSlice)
