import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { faFileAlt, faFileImage, faFilePdf } from '@fortawesome/pro-regular-svg-icons'
import * as React from 'react'
import AtomixIcon from '../icons/AtomixIcon'
import { FontAwesomeIconLoad } from '../icons/FontAwesomeIcon'
import classes from './downloads.module.scss'

export type DownloadListType = {
    id?: number
    title?: string
    type?: string
    size?: number
    downloadUrl?: string
}

export type DownloadProps = {
    children: React.ReactNode
    constrained: boolean
}

export enum downloadType {
    PDF = 'pdf',
    IMAGE = 'image',
    OTHER = 'doc/other'
}

const downloadIcon: Record<downloadType, IconDefinition> = {
  [downloadType.PDF]: faFilePdf,
  [downloadType.IMAGE]: faFileImage,
  [downloadType.OTHER]: faFileAlt,
}

const BYTE_CONVERT_VALUE = 1024

const downloadSizeGenerator = (bytes: number, decimals = 2) => {
  if (Number.isNaN(bytes)) return '(0 KB)'
  if (bytes === 0) return '(0 KB)'

  let k = BYTE_CONVERT_VALUE
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let i = Math.floor(Math.log(bytes) / Math.log(k))

  if (i===0) {
    k = BYTE_CONVERT_VALUE * 2
    i++
  }

  return '(' + Number.parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i] + ')'
}

export default function Download({ children,constrained }: DownloadProps) {
  return(
    <div className={`${classes.download} ${constrained&&classes.constrained}`}>
      <ul>
        {children}
      </ul>
    </div>
  )
}

export const DownloadItem: React.FC<React.PropsWithChildren<DownloadListType>> = ({ title, type, size, downloadUrl }) => {
  return (
    <li className="downloadLi">
      <a href={downloadUrl} className={classes.downloadLink} target={'_blank'} rel="noreferrer" download>
        <div className={classes.downloadType} >
          <FontAwesomeIconLoad className={classes.downloadTypeIcon} iconCode={downloadIcon[type as downloadType]} />
        </div>
        <div className={classes.downloadTitle} >

          {title}
        </div>
        <div className={classes.downloadSize} >

          {downloadSizeGenerator(size as number)}
        </div>
        <div className={classes.downloadButton} >
          <div className={classes.download}>{'Download'}</div>
          <AtomixIcon iconCode={'download'} className={classes['download__svg']} />
        </div>
      </a>
    </li>
  )
}
